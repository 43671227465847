import { Component } from "react";
import styled from "styled-components";

// Components
import Text from "../Text";

// Utils
import NameMap from "../../utils/nameMap";
import { Icons } from "../../utils/react-svg";
import { Color, Font, Opacity, media, rem } from "../../utils/style";

// Styled Elements
const ToggleWrapper = styled.div`
  position: relative;

  border-bottom: ${(p) => (p.open ? "none" : `2px solid ${Color.ritualBlue}`)};

  ${media.mobile`
    cursor: pointer;
  `};
`;

const ProductName = styled.div`
  position: relative;

  h2 {
    ${Font.circular};
    font-size: ${rem(20)};
    line-height: 30px;
    color: ${Color.ritualBlue};
    margin: 16px 0 8px;

    em {
      ${Font.dutch};
      font-style: italic;
    }
  }

  svg {
    position: absolute;
    top: calc(50% - 9px);
    right: 0;
    width: 18px;
    height: 18px;

    transform: ${(p) => (p.open ? "rotate(180deg)" : "rotate(0deg)")};
  }
`;

const Label = styled.p`
  color: rgba(20, 43, 111, ${Opacity.light});
  ${Font.circular}
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.8px;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

const IngredientRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
`;

const IngredientText = styled.p`
  ${Font.circular};
  color: ${Color.ritualBlue};
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  font-weight: 500;
  margin: 0;
  max-width: 14ch;
`;

const IngredientDosage = styled.p`
  ${Font.circular};
  color: ${Color.ritualBlue};
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  font-weight: 300;
  margin: 0;
  text-align: right;
  text-wrap: balance;
  max-width: 12ch;
`;

const IngredientDescription = styled.p`
  ${Font.dutch};
  color: ${Color.ritualBlue};
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  font-style: italic;
  font-weight: 300;
  margin: 0;
  flex: 0 0 100%;

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`;

const ListWrapper = styled.div`
  display: block;

  display: ${(p) => (p.open ? `block;` : `none`)};
  border-bottom: ${(p) => (p.open ? `2px solid ${Color.ritualBlue}` : "none")};
  padding-bottom: 16px;
`;

const BundleDescription = styled.p`
  font-weight: 300 !important;
  line-height: 20px;
  margin-bottom: 16px;
`;

export default class ModalIngredientList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  toggleList() {
    this.setState((prevState) => ({
      open: !prevState.open,
    }));
  }

  render() {
    const { open } = this.state;
    const { product } = this.props;

    if (!product) return null;

    const ingredients = product?.supplementFacts?.simpleIngredients;
    if (!ingredients) return null;

    const productName = NameMap(product.name);
    const shortDescription = product?.shortDescription;

    return (
      <>
        <ToggleWrapper open={open} onClick={this.toggleList.bind(this)}>
          <ProductName open={open}>
            <h2
              dangerouslySetInnerHTML={{
                __html: productName.html,
              }}
            ></h2>
            <Icons.CaretDownRounded />
          </ProductName>
          <BundleDescription>{shortDescription}</BundleDescription>
        </ToggleWrapper>
        <ListWrapper open={open}>
          <Label>
            <Text
              id="bundle-landing.bundle-modal.key-nutrients"
              defaultMessage="Key Nutrients"
            />
          </Label>
          {ingredients &&
            ingredients.map((ingredient, i) => {
              return (
                <div key={i}>
                  <IngredientRow key={i}>
                    <IngredientText>{ingredient.name}</IngredientText>
                    <IngredientDosage>{`${ingredient.dosage}`}</IngredientDosage>
                  </IngredientRow>
                  {ingredient.description && (
                    <IngredientDescription
                      dangerouslySetInnerHTML={{
                        __html: ingredient.description,
                      }}
                    />
                  )}
                </div>
              );
            })}
        </ListWrapper>
      </>
    );
  }
}
