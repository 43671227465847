import classNames from "classnames/bind";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import debounce from "lodash/debounce";
import { useState } from "react";
import styled, { css } from "styled-components";

// Components
import Ribbon from "../global/Ribbon";
import MagicLink from "../MagicLink";
import Sticker from "../Sticker";
import Text from "../Text";

// Store
import { useSelector } from "react-redux";
import promotionSelectors from "../../store/promotion/selectors";

// Utils
import { getPromotionDetails } from "../../utils/promotion";
import { Color, Font, Opacity, rem, responsive, rgba } from "../../utils/style";

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-width: 0;
  height: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
`;

const Button = styled.button`
  color: ${Color.ritualBlue};
  appearance: none;
  background: none;
  border: 0;
  padding: 0;
`;

const ContentContainerLinkWrapper = styled(MagicLink)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-width: 0;
  height: 100%;
`;

const ImageWrapperStyle = css`
  position: relative;
  width: 100%;
  margin-bottom: 12px;

  ${responsive.md`
    margin-bottom: 18px;
  `}
`;

const ImageWrapper = styled.div`
  ${ImageWrapperStyle}

  .gatsby-image-wrapper {
    aspect-ratio: 1 / 1;
  }
`;

const HeadlineStyle = css`
  ${Font.dutch};
  font-style: italic;
  font-weight: 300;

  font-size: ${rem(14)};
  line-height: ${rem(20)};
  margin-bottom: 2px;

  ${responsive.md`
    font-size: ${rem(18)};
    line-height: ${rem(28)};
    margin-bottom: 0px;
  `}
`;

const HeadlineText = styled.div`
  ${HeadlineStyle}
`;

const NameStyle = css`
  font-weight: 500;

  letter-spacing: 0px;
  font-size: ${rem(16)};
  line-height: ${rem(22)};
  white-space: normal;

  &.hide-description {
    padding-bottom: 14px;
  }

  ${responsive.md`
    letter-spacing: -0.2px;
    font-size: ${rem(22)};
    line-height: ${rem(32)};

    &.hide-description {
      padding-bottom: 24px;
    }
  `}
`;

const NameText = styled.div`
  ${NameStyle}
`;

const BundleNameText = styled.div`
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  font-weight: 500;
  margin-bottom: 2px;

  ${responsive.md`
    font-size: ${rem(16)};
    line-height: ${rem(24)};
  `}
`;

const DescriptionStyle = css`
  text-align: left;
  opacity: ${Opacity.light};

  font-weight: 300;
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  margin-top: 8px;
  margin-bottom: 14px;
  white-space: normal;

  ${responsive.md`
    font-size: ${rem(16)};
    line-height: ${rem(22)};
    margin-bottom: 24px;
  `}
`;

const DescriptionText = styled.div`
  ${DescriptionStyle}
`;

const BundleDescriptionText = styled.div`
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  font-weight: 300;
  color: var(--Solid-Primary-IndigoBlue-60, #62719f);

  margin-bottom: 14px;

  ${responsive.md`
    margin-bottom: 24px;
  `}
`;

const TextPrice = styled.p`
  ${DescriptionStyle}
  cursor: pointer;
  opacity: 1;
  margin-top: -6px;
  margin-bottom: 0;

  ${responsive.md`
    margin-bottom: 0;
  `}
`;

const Image = styled(GatsbyImage)`
  * {
    border-radius: 8px;
  }
`;

const ShopImage = styled(Image)``;

const HoverImage = styled(Image)`
  visibility: hidden;
  opacity: 0;

  &.hovered {
    visibility: visible;
    opacity: 1;
    transition: opacity 100ms ease-in;
  }
`;

const QuickViewStyle = css`
  font-weight: 500;
  letter-spacing: 0px;
  font-size: ${rem(16)};
  line-height: ${rem(26)};

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  width: 100%;
  height: 50px;

  left: 0px;
  bottom: 0px;

  background-color: ${rgba(Color.white, 0.88)};

  span {
    &:hover {
      opacity: ${Opacity.light};
    }
  }
`;

const QuickView = styled.div`
  ${QuickViewStyle}
`;

const ribbonStyle = css`
  position: absolute;
  z-index: 10;
  top: 12px;
  left: 12px;

  ${responsive.md`
    top: 16px;
    left: 16px;
  `};
`;

const Flag = styled.span`
  ${ribbonStyle};
  display: flex;
  padding: var(--Between-Components-Only-spacing-0_25, 4px)
    var(--Between-Components-Only-spacing-0_5, 8px);
  align-items: center;
  border-radius: var(--Between-Components-Only-spacing-0_25, 4px);
  background-color: ${Color.ritualYellow};
  color: ${Color.ritualBlue};
  font-size: ${rem(12)};
  line-height: ${rem(18)};
  font-weight: 400;
`;

const ShopCard = (props) => {
  const {
    images,
    headline,
    name,
    description,
    to,
    showSticker,
    handleClick,
    hideDescription,
    ribbonPromotion,
    showNewRibbon = false,
    showTextPrice = false,
    flag,
    isBundle = false,
  } = props;
  let names = props.names;
  // Map the name property to an array when online one name is passed.
  if (!names && name) names = [name];

  const [hovered, setHovered] = useState(false);

  const debouncedSetHover = debounce(setHovered, 25);

  function onImageEnter() {
    debouncedSetHover(true);
  }

  function onImageExit() {
    debouncedSetHover(false);
  }

  const shakerPromoEnabled = !!useSelector(
    promotionSelectors.activeProductPromotion,
  );
  const topText = <Text id="product.promotions.free" defaultMessage="Free" />;
  const bottomText = (
    <Text id="product.promotions.shaker" defaultMessage="Shaker" />
  );

  const Elements = {
    Name: isBundle ? BundleNameText : NameText,
    Description: isBundle ? BundleDescriptionText : DescriptionText,
  };

  return (
    <CardContainer>
      <ContentContainerLinkWrapper tabIndex={-1} to={to} onClick={handleClick}>
        <ContentContainer>
          <ImageWrapper
            onMouseOver={onImageEnter}
            onMouseLeave={onImageExit}
            onFocus={onImageEnter}
            onBlur={onImageExit}
          >
            {flag && (
              <Flag short={true} contentStyle={ribbonStyle}>
                {flag}
              </Flag>
            )}
            {showNewRibbon && (
              <Ribbon white={true} short={true} contentStyle={ribbonStyle}>
                <Text id="shop.new" defaultMessage="New" />
              </Ribbon>
            )}
            {!showNewRibbon && ribbonPromotion && (
              <Ribbon white={true} short={true} contentStyle={ribbonStyle}>
                <Text
                  id="shop.promotion-ribbon"
                  defaultMessage="Save {amount}"
                  values={{
                    amount: getPromotionDetails(ribbonPromotion).formattedValue,
                  }}
                />
              </Ribbon>
            )}
            {images && images[0] && (
              <ShopImage
                image={getImage(images && images[0])}
                alt={(images && images[0].description) || ""}
                style={{
                  width: "100%",
                }}
              />
            )}
            {showSticker && shakerPromoEnabled && (
              <Sticker bottomText={bottomText}>{topText}</Sticker>
            )}
            {images && images[1] && (
              <HoverImage
                className={classNames("d-none", "d-sm-block", { hovered })}
                image={getImage(images[1])}
                alt={images[1].description}
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  height: "100%",
                  width: "100%",
                }}
              />
            )}
            <QuickView
              className={classNames("d-none", "d-sm-flex", {
                "sr-only": !hovered,
              })}
            >
              <span>
                <Text id="shop.learn-more" defaultMessage="Learn More" />
              </span>
            </QuickView>
          </ImageWrapper>
          {!isBundle && <HeadlineText>{headline}</HeadlineText>}
          {names.map((name, i) => (
            <Elements.Name
              key={`${name}-${i}`}
              className={hideDescription && "hide-description"}
            >
              {name}
            </Elements.Name>
          ))}
          {!hideDescription && (
            <Elements.Description>{description}</Elements.Description>
          )}
          {showTextPrice && <TextPrice>{props.children}</TextPrice>}
        </ContentContainer>
      </ContentContainerLinkWrapper>
      {!showTextPrice && props.children}
    </CardContainer>
  );
};

export default ShopCard;
