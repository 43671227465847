import { useSelector } from "react-redux";
import { BillingType } from "../../constants/plan";
import { ActionType } from "../../constants/product";
import { Status } from "../../constants/stockStatus";
import useProductSpecificPromotion from "../../hooks/discount/useProductSpecificPromotion";
import useVariation from "../../hooks/useVariation";
import intl from "../../services/intl";
import planSelectors from "../../store/plan/selectors";
import promotionSelectors from "../../store/promotion/selectors";
import staticDataSelectors from "../../store/staticData/selectors";
import metrics from "../../utils/metrics";
import { ProductSKU } from "../../utils/product";
import { getPropertiesForProductSku } from "../../utils/tracking/helpers";
import DiscountButtonCopy from "../bundle/DiscountButtonCopy";
import ProductButton from "../product/ProductButton";
import ShopCard from "./ShopCard";

const ProductCard = ({
  product,
  hideDescription = false,
  hideCta = false,
  location,
  showTextPrice,
}) => {
  const productSpecificPromotion = useProductSpecificPromotion(
    ActionType.AddToCart,
    product,
  );

  const contentfulProduct = useSelector((state) =>
    staticDataSelectors
      .contentfulProducts(state)
      .find((contentfulProduct) => contentfulProduct.sku === product.sku),
  );

  const plans = useSelector((state) =>
    planSelectors.allPlansForProductSku(state, {
      sku: product.sku,
    }),
  );

  const promotion = useSelector(promotionSelectors.bestEligiblePromotion);

  if (!plans) return null;

  let plan = plans.find((plan) => plan.billingType === BillingType.Recurring);
  const hasMultiplePlans = plans.length > 1;

  if (!contentfulProduct || !plan) return null;

  function handleProductCTAClicked(e) {
    metrics.track("Product Clicked", {
      ...getPropertiesForProductSku(product.sku),
      location: location || "Product List Page Product Cards",
      title: "Learn More",
    });
  }

  function handleCheckoutCTAClicked(e) {
    metrics.track("CTA Clicked", {
      location: location || "Product List Page Product Cards",
      title: e?.target?.innerText,
    });
  }

  const productLink = `/products/${contentfulProduct.slug}`;

  const productButtonProps = {
    buttonLocation: location || "Shop All",
    prefix: intl.t("general.button-add", "Add"),
    className: "outlined fullwidth",
  };

  const statusCopy = {};
  statusCopy[Status.OutOfStock] = {
    label: intl.t("product.checkout.join-waitlist", "Join the Waitlist"),
    waitlisted: {
      label: intl.t("product.checkout.waitlisted", "Waitlisted ✓"),
    },
  };

  const smallProductButtonProps = {
    ...productButtonProps,
    prefix: intl.t("general.button-add", "Add"),
    className: "short outlined fullwidth",
    statusCopy,
  };

  const { sku, subCategory } = product;
  const showProductPromotion = subCategory === "Protein";

  const headline = contentfulProduct.productSubhead;
  const newFlagSKU =
    sku === ProductSKU.CHLC ||
    sku === ProductSKU.OM3C ||
    sku === ProductSKU.STRS;

  const aLaCarteEnabled = useVariation("a-la-carte", true);
  const pricePrefix =
    aLaCarteEnabled &&
    hasMultiplePlans &&
    intl.t("shop.starting-at", "Starts at ");

  return (
    <>
      <ShopCard
        images={contentfulProduct.shopImages}
        headline={headline}
        name={contentfulProduct.summary}
        description={contentfulProduct.shortDescription}
        to={productLink}
        showSticker={showProductPromotion}
        handleClick={handleProductCTAClicked}
        hideDescription={hideDescription}
        showNewRibbon={newFlagSKU}
        ribbonPromotion={productSpecificPromotion}
        showTextPrice={showTextPrice}
      >
        {!hideCta && (
          <>
            <div className="d-flex d-md-none w-100 align-self-end">
              <ProductButton
                product={contentfulProduct}
                trackCTAClick={handleCheckoutCTAClicked}
                productButtonProps={smallProductButtonProps}
                location="Product Shop Card"
              />
            </div>
            <div className="d-none d-md-flex w-100 align-self-end">
              <ProductButton
                product={contentfulProduct}
                trackCTAClick={handleCheckoutCTAClicked}
                productButtonProps={productButtonProps}
                location="Product Shop Card"
              />
            </div>
          </>
        )}
        {hideCta && showTextPrice === true && (
          <>
            <DiscountButtonCopy
              prefix={pricePrefix}
              showDash={false}
              price={plan.amount / 100}
              promotion={promotion}
            />
          </>
        )}
      </ShopCard>
    </>
  );
};

export default ProductCard;
