import { graphql } from "gatsby";
import { Component } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

// Services
import intl from "../../services/intl";

// Components
import RightArrow from "../../../static/react-svg/arrow-rounded-right.svg";
import InstagramShowcase from "../../components/InstagramShowcase";
import MagicLink from "../../components/MagicLink";
import Text from "../../components/Text";
import HeroBanner from "../../components/banners/HeroBanner";
import Ribbon from "../../components/global/Ribbon";
import CircleNew from "../../components/home/Circle.New";
import FeaturedArea from "../../components/home/FeaturedArea.New";
import FeaturedMerchandise from "../../components/home/FeaturedMerchandise";
import HomePageHero from "../../components/home/HomePageHero";
import Introduction from "../../components/home/Introduction";
import Press from "../../components/home/Press";
import ProductCategoryCards from "../../components/product/ProductCategoryCards";
import PageSEO from "../../components/seo/Page";
import Testimonials from "../../components/testimonials/Testimonials";

// Utils
import SubscriptionBenefits from "../../components/home/SubscriptionBenefits";
import useVariation from "../../hooks/useVariation";
import { bestEligiblePromotion } from "../../store/promotion/selectors";
import { Color, Opacity, responsive } from "../../utils/style";
import { setupTalkableHooks } from "../../utils/talkable";

const ContentWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

const CircleContainer = styled.div.attrs({
  className: "container",
})`
  position: relative;
  overflow: visible !important;
  padding: 0 !important;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const StyledMagicLink = styled(MagicLink)`
  margin-bottom: 0px !important;
`;

const NewRibbonWrapper = styled(Ribbon)`
  margin-left: 15px;
`;

const PressWrapper = styled.div`
  background: rgba(242, 241, 245, ${Opacity.light});

  ${responsive.md`
    padding-top: 80px;
  `}
`;

function MagicLinkRow({ link, text, icon, navigate, flag }) {
  return (
    <StyledRow>
      <StyledMagicLink className="-underline" to={link}>
        {text}
      </StyledMagicLink>
      {flag && <NewRibbonWrapper>New</NewRibbonWrapper>}
      {icon && (
        <RightArrow
          onClick={() => navigate(link)}
          style={{ marginLeft: ".5em" }}
        />
      )}
    </StyledRow>
  );
}

export default class Index extends Component {
  constructor(props) {
    super(props);
    const { pageTitle, pageDescription, socialImage } =
      props.data.contentfulHomePage;
    this.state = {
      talkableHooksArray: [
        "coupon_issued",
        "share_succeeded",
        "email_gating_passed",
        "email_gating_failed",
        "offer_loaded",
        "offer_triggered",
      ],
      seo: {
        pagePath: "",
        title: pageTitle,
        description: pageDescription,
        image: {
          url: socialImage.file.url,
          width: socialImage.file.details.image.width,
          height: socialImage.file.details.image.height,
        },
      },
      featuredProductVariation: "control",
    };

    this.ProductsLinks = [
      {
        link: "/products/essential-for-women-multivitamin",
        text: intl.t("home.links.efw-18", "Multivitamin for Women 18+"),
        icon: false,
      },
      {
        link: "/products/essential-prenatal-multivitamin",
        text: intl.t("home.links.epre", "The Prenatal Multivitamin"),
        icon: false,
      },
      {
        link: "/products/essential-multivitamin-for-men",
        text: intl.t("home.links.efm-18", "Multivitamin for Men 18+"),
        icon: false,
      },
      {
        link: "/products/essential-protein-daily-shake-18",
        text: intl.t("home.links.epro-18", "Essential Protein Daily Shake 18+"),
        icon: false,
        flag: true,
      },
    ];

    this.featuredProductsLinks = [
      {
        link: "/approach",
        text: intl.t("home.links.who-we-are", "Who We Are"),
        icon: false,
      },
    ];
  }

  componentDidMount() {
    this.props.updatePageData({
      label: "Home",
    });

    window._talkableq.push(["register_affiliate", {}]);
    const talkablePresent = typeof window.talkable !== "undefined";

    if (talkablePresent) {
      setupTalkableHooks(this.state.talkableHooksArray);
    }
  }

  render() {
    const { seo } = this.state;
    const { navigate } = this.props;
    const contentfulData = this.props.data;

    return (
      <>
        <PageSEO {...seo} />
        <PageContent
          contentfulData={contentfulData}
          navigate={navigate}
          clickableFeaturedData={this.featuredProductsLinks}
        />
      </>
    );
  }
}

function PageContent({ contentfulData, navigate, clickableFeaturedData }) {
  const {
    featuredArea1,
    featuredArea1Image,
    featuredArea1BackgroundColor,
    featuredArea2,
    featuredArea2Image,
    featuredArea2BackgroundColor,
    featuredArea3,
    featuredArea3List,
    featuredArea3Image,
    featuredArea3BackgroundColor,
    pressQuotes,
    instagramImages,
    merchandisingOption,
    products,
    bundles,
  } = contentfulData.contentfulHomePage;

  const homePageSubscriptionBenefits = useVariation(
    "home-page-subscription-experience",
    false,
  );
  const promotion = useSelector(bestEligiblePromotion);

  return (
    <>
      <HomePageHero promotionSlug={promotion?.slug} />
      <HeroBanner />
      <ProductCategoryCards className="mt-4--5 mt-sm-5 mt-md-6--5" />
      <ContentWrapper>
        <Introduction />
        <CircleContainer>
          <CircleNew className="left centerY" />
          <FeaturedArea
            indexId="0"
            align="right"
            image={featuredArea1Image}
            background={featuredArea1BackgroundColor}
            markdown={featuredArea1.childMarkdownRemark.html}
          >
            {clickableFeaturedData.map((key, index) => {
              return (
                <MagicLinkRow
                  navigate={navigate}
                  key={index}
                  link={key.link}
                  text={key.text}
                  icon={key.icon}
                  flag={key.flag}
                />
              );
            })}
          </FeaturedArea>
        </CircleContainer>
        <FeaturedArea
          align="left"
          indexId="1"
          image={featuredArea2Image}
          background={featuredArea2BackgroundColor}
          markdown={featuredArea2.childMarkdownRemark.html}
        >
          <MagicLink className="-underline" to="/ingredients">
            <Text
              id="home.links.meet-ingredients"
              defaultMessage="Meet Our Ingredients"
            />
          </MagicLink>
          <MagicLink className="-underline" to="/clinical-study">
            <Text
              id="home.links.clinical-study"
              defaultMessage="Our Clinical Study"
            />
          </MagicLink>
        </FeaturedArea>
        <FeaturedMerchandise
          className="mb-7 mb-md-9"
          merchandisingOption={merchandisingOption}
          products={products}
          bundles={bundles}
        />
        {homePageSubscriptionBenefits ? (
          <SubscriptionBenefits />
        ) : (
          <FeaturedArea
            indexId="2"
            list={featuredArea3List}
            icons={["Balloon", "Recurring", "Goodstuff", "CircleStar"]}
            align="right"
            image={featuredArea3Image}
            background={featuredArea3BackgroundColor}
            markdown={featuredArea3.childMarkdownRemark.html}
          />
        )}
        <Testimonials paddingBottom={0} />
        <PressWrapper>
          <Press quotes={pressQuotes}>
            <CircleNew className={"right bottom obscured"} />
          </Press>
        </PressWrapper>
      </ContentWrapper>
      <InstagramShowcase images={instagramImages} backgroundColor={Color.white}>
        <CircleNew className={"top right"} />
      </InstagramShowcase>
    </>
  );
}

export const pageQuery = graphql`
  fragment ProductBundleFragment on ContentfulProductBundle {
    title
    slug
    labels
    shopDescription
    shopImages {
      title
      gatsbyImageData(
        layout: CONSTRAINED
        placeholder: DOMINANT_COLOR
        width: 912
        quality: 90
      )
      description
    }
    products {
      sku
      stockStatus
      name {
        name
        childMarkdownRemark {
          html
        }
      }
      headerDescription
      heroFeaturedImages {
        title
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          width: 555
          quality: 90
        )
        description
      }
      supplementFacts {
        simpleIngredients {
          name
          dosage
          description
        }
      }
    }
  }

  query HomePageQuery($locale: String!) {
    contentfulHomePage(
      node_locale: { eq: $locale }
      contentful_id: { eq: "6jLz5EhYUoiwGqKUmQgAms" }
    ) {
      pageTitle
      pageDescription
      socialImage {
        file {
          details {
            image {
              width
              height
            }
          }
          url
        }
      }
      featuredArea1 {
        childMarkdownRemark {
          html
        }
      }
      featuredArea1Image {
        file {
          url
        }
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          width: 570
          quality: 90
        )
        description
      }
      featuredArea1BackgroundColor
      featuredArea2 {
        childMarkdownRemark {
          html
        }
      }
      featuredArea2Image {
        file {
          url
        }
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          width: 570
          quality: 90
        )
        description
      }
      featuredArea2BackgroundColor
      featuredArea3 {
        childMarkdownRemark {
          html
        }
      }
      featuredArea3List
      featuredArea3Image {
        file {
          url
        }
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          width: 570
          quality: 90
        )
        description
      }
      featuredArea3BackgroundColor
      merchandisingOption
      products {
        ...ProductCardFragment
        sku
        contentfulId: contentful_id
        productSubhead
      }
      bundles {
        ...ProductBundleFragment
        slug
      }
      instagramImages {
        title
        description
        gatsbyImageData(
          layout: FIXED
          placeholder: DOMINANT_COLOR
          width: 270
          height: 270
          quality: 90
        )
      }
      pressQuotes {
        author
        icon
        link
      }
    }
  }
`;
