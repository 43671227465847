import styled from "styled-components";

// Utils
import { Color, rem, responsive } from "../utils/style";

const StickerWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  top: 10px;
  left: 10px;
  background: ${Color.ritualYellow};
  border-radius: 100%;

  div {
    transform: rotate(-10deg);
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  span {
    color: ${Color.ritualBlue};
    text-transform: uppercase;
    width: fit-content;

    // Top Text
    &:first-of-type {
      font-size: ${rem(12)};
      line-height: ${rem(12)};
      letter-spacing: 0.77px;
    }
    // Bottom Text
    &:last-of-type {
      font-size: ${rem(7)};
      line-height: ${rem(12)};
      letter-spacing: 0.46px;
    }
  }

  ${responsive.md`
    height: 56px;
    width: 56px;
    top: 16px;
    left: 16px;

    span {
      // Main Text
      &:first-of-type {
        font-size: ${rem(13.5)};
        line-height: ${rem(14)};
        letter-spacing: 0.9px;
      }
      // Bottom Text
      &:last-of-type {
        font-size: ${rem(8)};
        line-height: ${rem(10)};
        letter-spacing: 0.53px;
      }
    }
  `}

  ${(p) => p.contentStyle}
`;

export default function Sticker({ children, contentStyle, bottomText }) {
  return (
    <StickerWrapper contentStyle={contentStyle}>
      <div>
        <span>{children}</span>
        <span>{bottomText}</span>
      </div>
    </StickerWrapper>
  );
}
