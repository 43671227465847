import { graphql, useStaticQuery } from "gatsby";
import { ImageDataLike } from "gatsby-plugin-image";
import intlService from "../../services/intl";

export type HomePageHeroNode = {
  heroTitle: {
    heroTitle: string;
  };
  heroImage: {
    gatsbyImageData: ImageDataLike;
  };
  heroImageMobile: {
    gatsbyImageData: ImageDataLike;
  };
  heroVideo: any;
  heroVideoMobile: any;
  heroBackgroundColor: string;
  heroTheme: string;
  heroCtas: {
    text: string;
    to: {
      slug: string;
    };
  }[];
};

const useHomePageHero = () => {
  const { allContentfulHomePage } = useStaticQuery(graphql`
    query HomePageHero {
      allContentfulHomePage {
        nodes {
          node_locale
          heroTitle {
            heroTitle
          }
          heroImage {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: DOMINANT_COLOR
              width: 1920
              quality: 90
            )
          }
          heroImageMobile {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: DOMINANT_COLOR
              width: 912
              quality: 90
            )
          }
          heroVideo {
            ...videoContent
          }
          heroVideoMobile {
            ...videoContent
          }
          heroBackgroundColor
          heroTheme
          heroCtas {
            text
            to {
              ... on ContentfulPageVariation {
                slug
              }
            }
          }
        }
      }
    }
  `);

  const localHomePageHero = intlService.cf(
    allContentfulHomePage.nodes,
    true,
  ) as HomePageHeroNode;

  const {
    heroImage: image,
    heroImageMobile: imageMobile,
    heroVideo: video,
    heroVideoMobile: videoMobile,
    heroBackgroundColor: backgroundColor,
    heroTitle: { heroTitle: title },
    heroTheme: theme,
    heroCtas,
  } = localHomePageHero;

  return {
    node: localHomePageHero,
    title,
    image,
    imageMobile,
    video,
    videoMobile,
    backgroundColor,
    theme,
    heroCtas,
  };
};

export default useHomePageHero;
