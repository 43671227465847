import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import intlService from "../../services/intl";
import { SubBenefits } from "../../utils/react-svg";
import { Font, rem, responsive } from "../../utils/style";
import { Color, Typography } from "../../utils/styleDesignSystem";
import MagicVideo from "../MagicVideo";
import Text from "../Text";

const VideoWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8px;

  & > div {
    overflow: hidden;
    border-radius: 8px;
  }
`;

const BenefitsTitle = styled.h2`
  font-size: ${rem(24)};
  line-height: ${rem(30)};
  letter-spacing: -0.24px;
  margin-bottom: 32px;

  ${responsive.sm`
    margin-bottom: 24px;
  `};

  ${responsive.md`
    font-size: ${rem(40)};
    line-height: ${rem(50)};
    letter-spacing: -0.8px;
    margin-bottom: 40px;
  `};

  em {
    ${Font.dutch};
  }
`;

const BenefitsList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;

  & > li {
    display: flex;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    & > span {
      margin-right: 12px;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    h3 {
      ${Typography.label2};
      margin: 0;
    }

    p {
      ${Typography.body2};
      ${Font.circular};
      color: ${Color.indigoBlue60};
      margin: 0;
    }

    ${responsive.md`
      margin-bottom: 24px;
    `};
  }
`;

const RitualPartnerTag = styled.span`
  position: absolute;
  bottom: -24px;
  left: 15px;

  font-size: ${rem(12)};
  line-height: ${rem(18)};
  font-weight: 500;
  color: ${Color.indigoBlue60};

  ${responsive.sm`
    left: 0;
  `};
`;

const query = graphql`
  query HomePageSubscriptionBenefits {
    allContentfulVideoContent(
      filter: {
        contentful_id: {
          in: [
            "12YoZ9aMDUs34HXw9cmfdC"
            "7mTZfOfqwbFV7IVVeQBPJN"
            "2sOgwyUIKePbnIdx95j42b"
            "2HT4eWQ5lHkEgeyJGmoYuK"
          ]
        }
      }
    ) {
      nodes {
        node_locale
        contentful_id
        ...videoContent
      }
    }
  }
`;

const Title = ({ className }) => (
  <BenefitsTitle className={className}>
    <em>
      <Text
        id="home.subscription-benefits.title.headline-em"
        defaultMessage="You subscribe"
      />
    </em>
    <Text
      id="home.subscription-benefits.title.headline"
      defaultMessage=" – we provide"
    />
  </BenefitsTitle>
);

const videoProps = {
  autoPlay: true,
  muted: true,
  loop: true,
  playsInline: true,
  controls: false,
  preload: "metadata",
  videoElementStyle: {
    width: "100%",
    height: "100%",
    userSelect: "none",
    pointerEvents: "all",
    userDrag: "none",
    touchCallout: "none",
  },
  videoStyle: { position: "static" },
};

const VideoOrder = [
  "12YoZ9aMDUs34HXw9cmfdC",
  "7mTZfOfqwbFV7IVVeQBPJN",
  "2sOgwyUIKePbnIdx95j42b",
  "2HT4eWQ5lHkEgeyJGmoYuK",
];

const Videos = ({ nodes }) => {
  // sort nodes based on VideoOrder
  const sortedNodes = nodes.sort((a, b) => {
    return (
      VideoOrder.indexOf(a.contentful_id) - VideoOrder.indexOf(b.contentful_id)
    );
  });

  return sortedNodes.map((node, index) => {
    return (
      <div
        className={index > 1 ? "d-none d-sm-block" : "d-block"}
        data-testid={"video"}
        key={index}
      >
        <MagicVideo
          {...videoProps}
          {...node}
          key={`video-${index}`}
          data-testid={"video"}
        />
      </div>
    );
  });
};

const SubscriptionBenefits = () => {
  const nodes = useStaticQuery(query);

  const localeNodes = intlService.cf(
    nodes.allContentfulVideoContent.nodes,
    false,
  );

  return (
    <div className="container pb-6 pb-sm-7 pb-md-9">
      <div className="row">
        <div className="col-12 d-sm-none">
          <Title />
        </div>
        <div className="col-12 col-sm-6 p-sm-0 mb-6 mb-sm-0 d-flex flex-column justify-content-center">
          <VideoWrapper>
            <Videos nodes={localeNodes} />
          </VideoWrapper>
          <RitualPartnerTag>
            <Text id="home.subscription-benefits.ritual-partner" />
          </RitualPartnerTag>
        </div>
        <div className="col-12 col-sm-5 offset-sm-1 d-flex flex-column justify-content-center">
          <Title className="d-none d-sm-block" />
          <BenefitsList>
            <li>
              <span className="sub-benefit-icon">
                <SubBenefits.StarCircle />
              </span>
              <div>
                <h3>
                  <Text id="home.subscription-benefits.benefit-1.lead" />
                </h3>
                <p>
                  <Text id="home.subscription-benefits.benefit-1.subheadline" />
                </p>
              </div>
            </li>
            <li>
              <span className="sub-benefit-icon">
                <SubBenefits.Shipping />
              </span>
              <div>
                <h3>
                  <Text id="home.subscription-benefits.benefit-2.lead" />
                </h3>
                <p>
                  <Text id="home.subscription-benefits.benefit-2.subheadline" />
                </p>
              </div>
            </li>
            <li>
              <span className="sub-benefit-icon">
                <SubBenefits.CircleX />
              </span>
              <div>
                <h3>
                  <Text id="home.subscription-benefits.benefit-3.lead" />
                </h3>
                <p>
                  <Text id="home.subscription-benefits.benefit-3.subheadline" />
                </p>
              </div>
            </li>
          </BenefitsList>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionBenefits;
