import { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Font, rem, responsive } from "../../utils/style";
import styled, { keyframes } from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import RitualButton from "../global/RitualButton";
import { throttle } from "lodash";

const FloatAnimation = keyframes`
  0% {
    transform: translateY(0) rotate(-8deg);
  }
  50% {
    transform: translateY(-7px) rotate(-8deg);
  }
  100% {
    transform: translateY(0) rotate(-8deg);
  }
`;

const FloatAnimationMobile = keyframes`
  0% {
    transform: translateY(0) rotate(-10deg);
  }
  50% {
    transform: translateY(-5px) rotate(-10deg);
  }
  100% {
    transform: translateY(0) rotate(-10deg);
  }
`;

const FadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  opacity: 0;

  animation: ${FadeIn} 1s 1s forwards;
`;

const Title = styled.h1`
  text-align: center !important;

  font-size: ${rem(51.666)};
  line-height: 90%;
  letter-spacing: -4%;
  font-weight: 500;
  margin-bottom: 20px;

  ${responsive.sm`
    font-size: ${rem(87.181)};
    line-height: 90%;
    letter-spacing: -4%;
    margin-bottom: 25px;
  `};
`;

const Subtitle = styled.h2`
  text-align: center !important;

  font-size: ${rem(18)};
  line-height: ${rem(26)};
  letter-spacing: -0.38px;
  font-weight: 500;
  max-width: 272px;

  em {
    ${Font.dutch};
    font-style: italic;
  }

  ${responsive.sm`
    max-width: unset;

    font-size: ${rem(20)};
    line-height: ${rem(30)};
    letter-spacing: -0.4px;
  `};

  br {
    ${responsive.sm`
      display: none;
    `};
  }
`;

const Pill = styled.span`
  position: relative;
`;

const PillImageWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 18px;
  bottom: 0;
  will-change: transform;

  ${responsive.sm`
    top: 25px;
  `};
`;

const PillImage = styled(GatsbyImage)`
  position: absolute !important;
  height: 48px;
  left: 0;
  right: 0;
  pointer-events: none;

  animation: ${FloatAnimationMobile} 2s infinite ease-in-out;

  filter: drop-shadow(-15px 15px 8px rgba(0, 0, 0, 0.25));

  ${responsive.sm`
    height: 80px;
    animation: ${FloatAnimation} 2s infinite ease-in-out;
  `};
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  gap: 16px;
  flex-direction: column;

  ${responsive.sm`
    flex-direction: row;
  `};
`;

const FloatingPill = ({ children, contentfulAsset, mousePosition }) => (
  <Pill>
    {contentfulAsset && (
      <PillImageWrapper
        style={{
          transform: `translate(${mousePosition.x}px, ${mousePosition.y}px)`,
        }}
      >
        <PillImage
          image={getImage(contentfulAsset.gatsbyImageData)}
          alt={contentfulAsset.title}
        />
      </PillImageWrapper>
    )}
    {children}
  </Pill>
);

const FloatingPillHero = () => {
  const { contentfulAsset } = useStaticQuery(
    graphql`
      query FloatingPillHeroVideoQuery {
        contentfulAsset(contentful_id: { eq: "5FXnyFo8hLutxX6Gy2vNDe" }) {
          title
          description
          gatsbyImageData(
            layout: CONSTRAINED
            width: 300
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    `,
  );

  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = throttle((e) => {
      const { clientX, clientY } = e;
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const xPosition = (clientX / screenWidth - 0.5) * 15 * -1;
      const yPosition = (clientY / screenHeight - 0.5) * 15 * -1;
      setMousePosition({
        x: xPosition,
        y: yPosition,
      });
    }, 20);
    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      if (handleMouseMove.cancel) {
        handleMouseMove.cancel();
      }
    };
  }, []);

  return (
    <Wrapper>
      <Title>
        Trace like a <br /> mother
        <FloatingPill
          contentfulAsset={contentfulAsset}
          mousePosition={mousePosition}
        >
          funker
        </FloatingPill>
        .
      </Title>
      <Subtitle>
        Know <em>where</em> every ingredient comes from, and <em>why</em> it's
        there.
      </Subtitle>
      <ButtonWrapper>
        <RitualButton
          to="/shop/daily-health"
          title="Shop All"
          className="alt-hover"
        >
          Shop Daily Health
        </RitualButton>
        <RitualButton
          to="/shop/pregnancy"
          title="Shop All"
          className="alt-hover"
        >
          Shop Pregnancy
        </RitualButton>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default FloatingPillHero;
