import styled from "styled-components";

// Utils
import { Font, Color, responsive } from "../../utils/style";

export const ModalTitle = styled.h1`
  ${Font.circular};
  color: ${Color.ritualBlue};
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.4px;
  line-height: 36px;

  em {
    ${Font.dutch};
    font-style: italic;
  }

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 16px 20px;
  border-bottom: 1px solid #ccc;
  background-color: ${Color.white};
  z-index: 10;

  ${responsive.sm`
    position: relative;
    top: unset;
    left: unset;
    right: unset;
    padding: 0;
    border: none;
    background: none;
    font-size: 30px;
    margin-bottom: 16px;
 `};
`;

export const ModalDescription = styled.p`
  ${Font.circular};
  color: ${Color.ritualBlue};
  color: rgb(20, 43, 111);
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0px;
  margin-bottom: 24px;

  ${responsive.sm`
    margin-bottom: 0;
 `};
`;

export const ModalDescriptionTopMargin = styled(ModalDescription)`
  margin: 61px 0 24px;

  ${responsive.sm`
    margin-top: 0;
    margin-bottom: 0;
 `};
`;
