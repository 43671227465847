import styled, { css } from "styled-components";

// Services
import cartService from "../../services/cart";
import intl from "../../services/intl";

// Utils
import {
  getBundleContents,
  getBundleTotal,
  getPlanIdAndQuantityArray,
} from "../../utils/bundle";
import { getPlanIdForProductSku } from "../../utils/planToProduct";
import { Color, Opacity, rem, responsive } from "../../utils/style";

// Components
import MagicModal from "../MagicModal";
import DiscountButton from "../bundle/DiscountButton";
import { ModalDescription, ModalTitle } from "../bundle/index";
import ModalIngredientList from "./ModalIngredientList";

// Store
import { useDispatch, useSelector } from "react-redux";
import { addProductsToCart, addProductToCart } from "../../store/cart/actions";
import { productOfferForId } from "../../store/product-offer/selectors";
import { getStore } from "../../store/createStore";

// Styled Elements
const contentStyle = css`
  padding: 24px 20px;

  ${responsive.sm`
    width: 700px;
    padding: 56px 40px;
  `};

  ${responsive.md`
    width: 774px;
    padding: 56px 80px;
  `};

  ${responsive.lg`
    width: 782px;
    padding: 56px 100px;
  `};
`;

const threeProductContentStyle = css`
  padding: 24px 20px;

  ${responsive.sm`
    width: 700px;
    padding: 56px 30px;
  `};

  ${responsive.md`
    width: 774px;
    padding: 56px 60px;
  `};

  ${responsive.lg`
    width: 782px;
    padding: 56px 80px;
  `};
`;

const Divider = styled.div`
  background: ${Color.ritualBlue};
  height: 2px;
  margin-top: 32px;

  ${responsive.sm`
    margin-top: 24px;
    margin-bottom: 0;
  `};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
`;

const BundleIncludes = styled.p`
  color: rgba(20, 43, 111, ${Opacity.light});
  font-size: ${rem(12)};
  line-height: ${rem(20)};
  letter-spacing: 0.8px;
  text-transform: uppercase;
  margin: 61px 0 8px;

  ${responsive.sm`
    margin-top: 0;
  `};
`;

const BundleCTA = styled(DiscountButton)`
  width: 100%;
`;

function getPrice(bundle, productOffer) {
  if (productOffer) {
    return productOffer.initialAmount(getStore().getState()) / 100;
  }

  return getBundleTotal(bundle);
}

const BundleModal = ({ isOpen, onRequestClose, bundle, hideCTA = false }) => {
  const dispatch = useDispatch();

  function handleStartNowClick(e) {
    const { products, ritualProductOffer } = bundle;

    if (ritualProductOffer) {
      dispatch(
        addProductToCart({
          productOfferId: ritualProductOffer.product_offer_id,
        }),
      );
    } else {
      const planIds = products.map((product) =>
        getPlanIdForProductSku(product.sku),
      );

      dispatch(addProductsToCart(getPlanIdAndQuantityArray(planIds)));
    }

    if (onRequestClose) onRequestClose();
    cartService.openCart(e);
  }

  const productOffer = useSelector((state) =>
    productOfferForId(state, bundle?.ritualProductOffer?.product_offer_id),
  );

  if (!bundle) return null;

  const { title, description, products } = bundle;
  if (!products) return null;

  const bundleContent = getBundleContents(products.map((p) => p.sku));

  const threeColumns = products.length > 2;

  return (
    <MagicModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentStyle={threeColumns ? threeProductContentStyle : contentStyle}
      mobileFixedClose={true}
      mobileFullPage={true}
    >
      <ModalTitle>{title}</ModalTitle>
      <BundleIncludes>{bundleContent}</BundleIncludes>
      <ModalDescription>{description}</ModalDescription>

      {!hideCTA && (
        <BundleCTA
          prefix={intl.t("general.button-add-cart", "Add to Cart")}
          onClick={handleStartNowClick}
          price={getPrice(bundle, productOffer)}
        />
      )}
      <Divider />
      <Row>
        {products.map((product, i) => {
          return (
            <Column key={i} threeColumns={threeColumns}>
              <ModalIngredientList product={product} />
            </Column>
          );
        })}
      </Row>
    </MagicModal>
  );
};

export default BundleModal;
