import styled from "styled-components";

// Redux
import { useSelector } from "react-redux";
import cartProductSelectors from "../../store/cart-product/selectors";

// Hooks

// Components
import MagicLink from "../../components/MagicLink";
import PulseIcon from "../../components/animation/PulseIcon";

// Utils
import { ActionType } from "../../constants/product";
import useBundlePromotion from "../../hooks/discount/useBundlePromotion";
import intl from "../../services/intl";
import { getPromotionDetails } from "../../utils/promotion";
import { Color, Opacity, rem, responsive } from "../../utils/style";

const BannerWrapper = styled(MagicLink)``;

const BannerContent = styled.div`
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  font-weight: 300;
  text-align: center;
  width: calc(100% - 16px);
  padding: 16px 0;
  background-color: ${Color.ritualLightYellow};
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  .banner-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &:hover {
      .promo-copy {
        opacity: ${Opacity.light};
      }
    }

    em {
      font-style: normal;
      position: relative;

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: ${Color.ritualBlue};
        position: absolute;
        bottom: -2px;
        left: 0;
        right: 0;
      }
    }
  }

  ${responsive.sm`
    width: calc(100% - 48px);
  `}

  ${responsive.md`
    font-size: ${rem(16)};
    line-height: ${rem(24)};
  `}

  ${responsive.lg`
    width: calc(100% - 80px);
  `}
`;

const PulseContainer = styled.div`
  margin-right: 2px;

  ${responsive.md`
    margin-right: 6px;
  `}
`;

const HeroBanner = () => {
  const activeCartProductQuantity = useSelector(
    cartProductSelectors.activeCartProductQuantity,
  );
  const bundlePromotion = useBundlePromotion(ActionType.AddToCart, 2);

  if (activeCartProductQuantity >= 2 || !bundlePromotion) {
    return null;
  }

  const promotionDetails = getPromotionDetails(bundlePromotion);
  const bundleCopy = intl.t(
    "home.hero.bundle-promo-banner",
    "Save {discountCopy} on your first order when you <em>bundle</em>",
    {
      discountCopy: promotionDetails.formattedValue,
    },
  );

  return (
    <BannerWrapper to={"/shop/bundles"}>
      <BannerContent>
        <div className="banner-text">
          <PulseContainer>
            <PulseIcon className={{ big: true }} />
          </PulseContainer>
          <div
            className="promo-copy"
            dangerouslySetInnerHTML={{
              __html: bundleCopy,
            }}
          />
          .
        </div>
      </BannerContent>
    </BannerWrapper>
  );
};

export default HeroBanner;
