import { useState } from "react";
// Redux
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

// Constants
import { Status } from "../../constants/stockStatus";

// Components
import DiscountButton from "../bundle/DiscountButton";
import DiscountButtonCopy from "../bundle/DiscountButtonCopy";
import BundleModal from "../bundleLanding/BundleModal";
import ShopCard from "./ShopCard";

// Services
import cartService from "../../services/cart";
import intl from "../../services/intl";

// Utils
import { getBundleTotal, getPlanIdAndQuantityArray } from "../../utils/bundle";
import metrics from "../../utils/metrics";
import { getPlanForProductSku } from "../../utils/planToProduct";
import { getPropertiesForProductSku } from "../../utils/tracking/helpers";
import { addProductsToCart, addProductToCart } from "../../store/cart/actions";

// Hooks
import promotionSelectors from "../../store/promotion/selectors";
import Currency from "../Currency";
import { rem, responsive } from "../../utils/style";
import StrikeText from "../global/StrikeText";
import useVariation from "../../hooks/useVariation";
import {
  allProductOffersByCurrentStore,
  productOfferForId,
} from "../../store/product-offer/selectors";
import { getStore } from "../../store/createStore";

const BundlePrice = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  text-align: left;
  margin-bottom: var(--Between-Components-Only-spacing-0_75, 12px);
  margin-top: -6px;

  ${responsive.md`
    flex-direction: row;
    margin-bottom: var(--between-components-modules-spacing-1, 16px);
    margin-top: -16px;
  `}

  span {
    font-weight: 400;
    width: 100%;

    &:first-child {
      font-size: ${rem(14)};
      line-height: ${rem(20)};

      ${responsive.md`
        font-size: ${rem(16)};
        line-height: ${rem(24)};
      `}
    }

    &.savings {
      color: var(--Solid-Semantic-SuccessGreen, #4c840d);
      font-size: ${rem(12)};
      line-height: ${rem(18)};
      margin-top: var(--Between-Components-Only-spacing-0_5, 8px);

      ${responsive.md`
        text-align: right;
        margin-top: unset;
      `};
    }
  }
`;

const BundlePriceElement = ({ price, futurePrice, savings, shouldShow }) => {
  if (!shouldShow) return null;

  return (
    <BundlePrice>
      <span>
        <Currency value={price} />
        {futurePrice && (
          <span>
            <StrikeText>
              <Currency value={futurePrice} />
            </StrikeText>
          </span>
        )}
      </span>
      <span className="savings">
        <Currency value={savings} round={true} /> savings
      </span>
    </BundlePrice>
  );
};

function productOfferInCurrentStore(productOfferRedux) {
  const allProductOffersForStore = useSelector(allProductOffersByCurrentStore);
  return allProductOffersForStore.find(
    (productOffer) => productOfferRedux.id === productOffer.id,
  );
}

function getFuturePrice(productOfferRedux) {
  if (!productOfferRedux) return 0;

  return productOfferRedux.futureAmount(getStore().getState()) / 100;
}

const BundleCard = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();

  const promotion = useSelector(promotionSelectors.bestEligiblePromotion);
  const productOfferPlp = useVariation("product-offer-plp");

  function handleCheckoutCTAClicked(e) {
    metrics.track("CTA Clicked", {
      location,
      title: e?.target?.innerText,
      name: getDetailedName()
    });

    if (ritualProductOffer) {
      dispatch(
        addProductToCart({
          productOfferId: ritualProductOffer.product_offer_id,
        }),
      );
    } else {
      const plansToAdd = getPlanIdAndQuantityArray(plans.map((p) => p.id));
      dispatch(addProductsToCart(plansToAdd));
    }
    cartService.openCart(e);
  }

  function handleQuickViewClick(e) {
    e.preventDefault();

    metrics.track("Product Clicked", {
      location,
      name: getDetailedName(),
      title: "Quick View",
      products: productBundle.products.map((p) =>
        getPropertiesForProductSku(p.sku),
      ),
    });

    setModalOpen(true);
  }

  function getDetailedName() {
    let name = productBundle.title;
    productBundle.labels.forEach((label) => {
      name += ` ${label}`;
    });
    return name;
  }

  function closeModal() {
    setModalOpen(false);
  }

  const {
    productBundle,
    hideCta,
    showTextPrice,
    location = "Product List Page Bundle Cards",
  } = props;

  const { shopImages, ritualProductOffer, flag } = productBundle;

  const productOfferRedux = useSelector((state) =>
    productOfferForId(state, ritualProductOffer?.product_offer_id),
  );

  if (productOfferRedux && !productOfferInCurrentStore(productOfferRedux)) {
    return null;
  }

  const headline = productBundle.title;

  const plans = productBundle.products
    .map((p) => getPlanForProductSku(p.sku))
    .filter((p) => p);
  if (!plans.length) return null;

  let price = getBundleTotal(productBundle);
  if (productOfferRedux) {
    price = productOfferRedux.initialAmount(getStore().getState()) / 100;
  }

  const futurePrice = getFuturePrice(productOfferRedux);

  const savings = Math.floor(((futurePrice - price) * 100) / 100).toFixed(0);

  let discountButtonProps = {
    to: "/cart",
    noNavigate: true,
    onClick: handleCheckoutCTAClicked,
    price: ritualProductOffer ? undefined : price,
    prefix: ritualProductOffer
      ? "Add to Cart"
      : intl.t("general.button-add", "Add"),
    className: "outlined fullwidth",
    promotion,
    showDash: !ritualProductOffer,
  };
  if (ritualProductOffer) {
    delete discountButtonProps.promotion;
  }

  const smallDiscountButtonProps = {
    ...discountButtonProps,
    className: "outlined short fullwidth",
  };

  const hasOOSProduct = productBundle.products.find(
    (p) => p.stockStatus !== Status.InStock,
  );

  if (hasOOSProduct) return null;

  const promotionInUse = promotion;

  const bundlePriceOptions = {
    price,
    futurePrice,
    savings,
    shouldShow: !!ritualProductOffer,
  };

  if (ritualProductOffer && !productOfferPlp) return null;

  return (
    <>
      <ShopCard
        images={shopImages}
        headline={ritualProductOffer ? undefined : headline}
        names={
          ritualProductOffer ? [ritualProductOffer.name] : productBundle.labels
        }
        description={productBundle.shopDescription}
        handleClick={handleQuickViewClick}
        handleQuickViewClick={handleQuickViewClick}
        showTextPrice={showTextPrice}
        flag={flag}
        isBundle={true}
      >
        {!hideCta && (
          <>
            <div className="d-block d-md-none w-100">
              <BundlePriceElement {...bundlePriceOptions} />
              <DiscountButton {...smallDiscountButtonProps} />
            </div>
            <div className="d-none d-md-block w-100">
              <BundlePriceElement {...bundlePriceOptions} />
              <DiscountButton {...discountButtonProps} />
            </div>
          </>
        )}
        {showTextPrice && (
          <span className="justify-flex-start">
            <DiscountButtonCopy price={price} promotion={promotionInUse} />
          </span>
        )}
      </ShopCard>

      <BundleModal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        bundle={productBundle}
        promotionOverride={promotionInUse}
      />
    </>
  );
};

export default BundleCard;
