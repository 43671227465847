import { graphql, useStaticQuery } from "gatsby";
import {
  RenderRichTextData,
  renderRichText,
} from "gatsby-source-contentful/rich-text";
import intlService from "../../services/intl";
import { ComponentType } from "react";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { ImageDataLike } from "gatsby-plugin-image";

export type HomePageHeroPromotionNode = {
  promotionSlug: string;
  content: RenderRichTextData<any>;
  ctas: {
    text: string;
    to: {
      slug: string;
    };
  }[];
  backgroundImageDesktop: {
    gatsbyImageData: ImageDataLike;
  };
  backgroundImageMobile: {
    gatsbyImageData: ImageDataLike;
  };
  backgroundColor: string;
};

export type Heading1Component = ComponentType<{ children: React.ReactNode }>;
export type ParagraphComponent = ComponentType<{ children: React.ReactNode }>;
export type RenderContent = {
  H1?: Heading1Component;
  P?: ParagraphComponent;
};

const parseNode = (
  node: HomePageHeroPromotionNode,
  { H1, P }: RenderContent = {},
) => {
  const Heading1 = H1 ? H1 : "h1";
  const Paragraph = P ? P : "p";
  const content = renderRichText(node.content, {
    renderNode: {
      [BLOCKS.HEADING_1]: (_, children) => <Heading1>{children}</Heading1>,
      [BLOCKS.PARAGRAPH]: (_, children) => <Paragraph>{children}</Paragraph>,
    },
    renderMark: {
      [MARKS.ITALIC]: (text) => <em>{text}</em>,
      // Repurposing Subscript for Strikethrough since Contentful does not offer a strikethrough mark
      [MARKS.SUBSCRIPT]: (text) => <s>{text}</s>,
    },
  });

  // const ctas = node.ctas.map(({ text, to }) => ({ text, to: "/" + to.slug }));

  return { node, content, ctas: node.ctas };
};

type UseHomePageHeroPromotionOpts = { renderContent?: RenderContent };
const useHomePageHeroPromotion = (
  promotionSlug?: string,
  { renderContent }: UseHomePageHeroPromotionOpts = {},
) => {
  const { allContentfulHomePageHeroPromotion } = useStaticQuery(
    graphql`
      query HomePageHeroPromotion {
        allContentfulHomePageHeroPromotion {
          nodes {
            node_locale
            promotionSlug
            content {
              raw
            }
            ctas {
              text
              to {
                ... on ContentfulBundleLandingPage {
                  slug
                }
                ... on ContentfulShopLandingPage {
                  slug
                }
              }
            }
            backgroundImageDesktop {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: DOMINANT_COLOR
                width: 1920
                quality: 90
              )
            }
            backgroundImageMobile {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: DOMINANT_COLOR
                width: 912
                quality: 90
              )
            }
            backgroundColor
          }
        }
      }
    `,
  );
  const localNodes = intlService.cf(
    allContentfulHomePageHeroPromotion.nodes,
  ) as HomePageHeroPromotionNode[];
  const node = localNodes.find((n) => n.promotionSlug === promotionSlug);

  return node ? parseNode(node, renderContent) : null;
};

export default useHomePageHeroPromotion;
