import { graphql, StaticQuery } from "gatsby";
import { Component } from "react";
import styled from "styled-components";
import ValuePropsNew from "../../components/product/ValueProps.New";
// Services
import intl from "../../services/intl";
import { media, rem, responsive } from "../../utils/style";
import Container from "../Container";
import Row from "../Row";

const componentQuery = graphql`
  query IntroductionComponentQuery {
    allContentfulHomePage {
      nodes {
        node_locale
        introText {
          childMarkdownRemark {
            html
          }
        }
        valueProps {
          name
          icon {
            file {
              url
              fileName
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`;

const IntroContainer = styled(Container).attrs({
  id: "introduction-container",
})`
  position: relative;
  z-index: 2;
  margin-top: 48px;
  margin-bottom: 80px;

  ${media.mobile`
    padding: 0 20px;
  `}

  ${responsive.sm`
    margin-top: 48px;
    margin-bottom: 80px;
  `};

  ${responsive.md`
    margin-top: 80px;
    margin-bottom: 120px;
  `};
`;

const IntroColumn = styled.div.attrs({
  id: "introduction-container_row_container_intro-column",
  className: "col-12 col-sm-8 offset-sm-2 col-lg-10 offset-lg-1",
})`
  position: relative;
  z-index: 1;
  margin-bottom: 40px;
`;

const IntroHeader = styled.h2.attrs({
  id: "introduction-container_row_container_intro-column_title",
})`
  position: relative;
  text-align: center !important;
  margin: 0;
  font-size: ${rem(22)};
  line-height: ${rem(32)};
  font-weight: 500;
  letter-spacing: -0.2px;

  ${media.mobile`
    text-align: left !important;
  `};
`;

export const IntroductionComponent = class Introduction extends Component {
  render() {
    const data = intl.cf(this.props.data.allContentfulHomePage.nodes, true);

    return (
      <IntroContainer>
        <Row id="introduction-container_row">
          <IntroColumn>
            <IntroHeader
              dangerouslySetInnerHTML={{
                __html: data.introText.childMarkdownRemark.html
                  .replace("<p>", "")
                  .replace("</p>", ""),
              }}
            />
          </IntroColumn>
          <ValuePropsNew
            valueProps={data.valueProps}
            className="col-md-10 col-lg-8 offset-md-1 offset-lg-2"
            removePadding={true}
          />
        </Row>
      </IntroContainer>
    );
  }
};

export const IntroductionComponentQuery = (props) => (
  <StaticQuery
    query={componentQuery}
    render={(data) => <IntroductionComponent {...props} data={data} />}
  />
);

export default IntroductionComponentQuery;
